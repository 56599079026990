import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

export interface HistoricalEvent {
  id?: string | null;
  slug: string | null;
  title: string | null;
  description: string | null;
  day: number | null;
  month: number | null;
  imagePath?: string | null;
  created: number | null;
  updated: number | null;
}

@Injectable({ providedIn: 'root' })
export class HistoricalEventsService extends CrudService<HistoricalEvent> {

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}
